import "./App.css";
import { RouterProvider } from "react-router-dom";

import { mainWebsiteRoutes } from "./routes/main.routes";
import { getSubDomainName } from "./lib/utils";
import { subDomainRoutes } from "./routes/app.routes";
import {  Suspense } from "react";
import  ScreenLoader from "./components/ScreenLoader";

function App() {
  const isSubDomain = getSubDomainName();

  const routes = isSubDomain ? subDomainRoutes : mainWebsiteRoutes;

  return (
    <Suspense fallback={<ScreenLoader />}>
      <RouterProvider router={routes} />
    </Suspense>
  );
}

export default App;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { OrganizationAuthSchema } from "../../constants/validations/organization";
import { z } from "zod";
import { useMutation } from "react-query";
import usePublicAPIs from "../../hooks/usePublicAPIs";
import { toast } from "../../components/ui/use-toast";
import { Input } from "@/components/ui/input";
import { Button, buttonVariants } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CloudLightning, PersonStanding, ShieldCheck } from "lucide-react";
import { cn } from "../../lib/utils";
import { BookingCloudIcon } from "../../assets/Icons/icon";

type OrgFormData = z.infer<typeof OrganizationAuthSchema>;

const SignUpOrganization = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<OrgFormData>({
    resolver: zodResolver(OrganizationAuthSchema),
    mode: "onChange",
  });

  const { createOrganization } = usePublicAPIs();

  const { mutateAsync } = useMutation({
    mutationKey: ["createOrganization"],
    mutationFn: createOrganization,
    onSuccess: () => {
      toast({
        title: "Organization Created",
        variant: "success",
        description: "Your organization has been created successfully.",
      });

      const subdomain = getValues("accountUrl");

      const subdomainUrl = `http://${subdomain}.localhost:3000/`;
      window.location.href = subdomainUrl;
    },
    onError(error: any) {
      toast({
        title: "Oops! Something went wrong",
        description: `Error: ${error.response?.data?.message}`,
        variant: "destructive",
      });
    },
  });

  const handleOnSubmit = async (data: OrgFormData) => {
    try {
      setIsLoading(true);
      const organization = {
        name: data.name,
        subDomain: data.accountUrl,
        customerEmail: data.customerEmail,
        currency: data.currency,
        staffUser: {
          person: {
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            dateOfBirth: data.dateOfBirth,
          },
          credentials: {
            email: data.email,
            password: data.password,
          },
        },
      };
      await mutateAsync(organization);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = async () => {
    const fieldsToValidate = [
      "firstName",
      "lastName",
      "email",
      "password",
      "gender",
      "dateOfBirth",
    ];
    const isValid = await trigger(
      fieldsToValidate as (
        | "email"
        | "password"
        | "firstName"
        | "lastName"
        | "name"
        | "currency"
        | "accountUrl"
        | "gender"
        | "dateOfBirth"
        | "customerEmail"
      )[],
    );
    if (isValid) setStep(2);
  };

  const prevStep = () => setStep(1);

  const inputClassName =
    "border border-[#C5D6EB] text-[#182143] text-sm focus:border-[#3C66FA] focus:border rounded-3xl py-5 px-3";

  return (
    <div className="flex min-h-screen body_color text-black p-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        {/* Left side content */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <div className="flex items-center gap-4 mb-6">
            <BookingCloudIcon />
            <h1 className="text-2xl font-bold">Booking Cloud</h1>
          </div>
          <h2 className="text-4xl font-bold mb-4">Create Your Organization</h2>
          <p className="text-gray-400 mb-8">Set up your workspace in minutes</p>

          <div className="space-y-6">
            <Feature
              icon={
                <PersonStanding className="text-primary_colour" size={24} />
              }
              title="Collaborate with your team"
              description="Invite unlimited colleagues and manage your organization efficiently."
            />
            <Feature
              icon={<ShieldCheck className="text-primary_colour" size={24} />}
              title="Secure and compliant"
              description="Our platform ensures your data is protected and meets industry standards."
            />
            <Feature
              icon={
                <CloudLightning className="text-primary_colour" size={24} />
              }
              title="Streamlined workflow"
              description="Boost productivity with our intuitive tools and integrations."
            />
          </div>
        </div>

        {/* Right side form */}
        <Card className="lg:w-1/2  bg-white p-8 rounded-3xl">
          <div className="flex justify-between mb-6">
            <div
              className={`w-1/2 text-center pb-2 ${step === 1 ? "border-b-2 border-primary_colour" : ""}`}
            >
              Account Details
            </div>
            <div
              className={`w-1/2 text-center pb-2 ${step === 2 ? "border-b-2 border-primary_colour" : ""}`}
            >
              Organization Details
            </div>
          </div>

          <form onSubmit={handleSubmit(handleOnSubmit)} className="space-y-8">
            {step === 1 && (
              <>
                <div className="grid grid-cols-2 gap-6">
                  <Input
                    placeholder="First Name"
                    className={inputClassName}
                    {...register("firstName")}
                  />
                  <Input
                    placeholder="Last Name"
                    className={inputClassName}
                    {...register("lastName")}
                  />
                </div>
                {(errors.firstName || errors.lastName) && (
                  <p className="text-red-500 text-xs">
                    Please enter your full name
                  </p>
                )}

                <Input
                  placeholder="Email"
                  type="email"
                  className={inputClassName}
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">{errors.email.message}</p>
                )}

                <Input
                  placeholder="Password"
                  type="password"
                  className={inputClassName}
                  {...register("password")}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">
                    {errors.password.message}
                  </p>
                )}

                <Select onValueChange={(value) => setValue("gender", value)}>
                  <SelectTrigger className={inputClassName}>
                    <SelectValue placeholder="Select Gender" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="MALE">Male</SelectItem>
                    <SelectItem value="FEMALE">Female</SelectItem>
                    <SelectItem value="OTHER">Other</SelectItem>
                  </SelectContent>
                </Select>
                {errors.gender && (
                  <p className="text-red-500 text-xs">
                    {errors.gender.message}
                  </p>
                )}

                <Input
                  placeholder="Date of Birth"
                  type="date"
                  className={inputClassName}
                  max={new Date().toISOString().split("T")[0]}
                  {...register("dateOfBirth")}
                />
                {errors.dateOfBirth && (
                  <p className="text-red-500 text-xs">
                    {errors.dateOfBirth.message}
                  </p>
                )}

                <div className="flex items-center justify-center mt-5">
                  <Button
                    type="button"
                    onClick={nextStep}
                    className={cn(
                      buttonVariants(),
                      "border bg-primary_colour hover:bg-hover_primary rounded-3xl cursor-pointer text-white w-[80%] px-4 py-5",
                    )}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <Input
                  placeholder="Organization Name"
                  className={inputClassName}
                  {...register("name")}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs">{errors.name.message}</p>
                )}

                <div className="flex items-center">
                  <span className="bg-gray-100 text-gray-600 px-3 py-2 rounded-l-3xl border border-r-0 border-[#C5D6EB]">
                    https://
                  </span>
                  <Input
                    placeholder="your-subdomain"
                    className="border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-800 text-sm rounded-none h-10 px-3 flex-grow outline-none disabled:cursor-not-allowed disabled:opacity-50"
                    {...register("accountUrl")}
                  />
                  <span className="bg-gray-100 text-gray-600 px-3 py-2 rounded-r-3xl border border-l-0 border-[#C5D6EB]">
                    .bookingcloud.com
                  </span>
                </div>
                {errors.accountUrl && (
                  <p className="text-red-500 text-xs">
                    {errors.accountUrl.message}
                  </p>
                )}

                <Input
                  placeholder="Customer Email"
                  type="email"
                  className={inputClassName}
                  {...register("customerEmail")}
                />
                {errors.customerEmail && (
                  <p className="text-red-500 text-xs">
                    {errors.customerEmail.message}
                  </p>
                )}

                <Select onValueChange={(value) => setValue("currency", value)}>
                  <SelectTrigger className={inputClassName}>
                    <SelectValue placeholder="Select Currency" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="USD">USD</SelectItem>
                    <SelectItem value="EUR">EUR</SelectItem>
                    <SelectItem value="GBP">GBP</SelectItem>
                  </SelectContent>
                </Select>
                {errors.currency && (
                  <p className="text-red-500 text-xs">
                    {errors.currency.message}
                  </p>
                )}

                <div className="flex justify-between mt-5">
                  <Button
                    type="button"
                    onClick={prevStep}
                    className={cn(
                      buttonVariants(),
                      "border border-primary_colour hover:bg-[#E5E7F0] rounded-3xl cursor-pointer text-primary_colour w-[48%] px-4 py-5",
                    )}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className={cn(
                      buttonVariants(),
                      "border bg-primary_colour hover:bg-hover_primary rounded-3xl cursor-pointer text-white w-[48%] px-4 py-5",
                    )}
                    disabled={isLoading}
                  >
                    {isLoading ? "Creating..." : "Create Organization"}
                  </Button>
                </div>
              </>
            )}
          </form>

          <p className="text-xs text-gray-400 mt-4">
            By creating an account, you agree to our Terms of Service and
            Privacy Policy.
          </p>
        </Card>
      </div>
    </div>
  );
};

const Feature = ({
  icon,
  title,
  description,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) => (
  <div className="flex items-start">
    <div className="flex-shrink-0 mr-4">{icon}</div>
    <div>
      <h3 className="font-semibold mb-1">{title}</h3>
      <p className="text-sm text-gray-400">{description}</p>
    </div>
  </div>
);

export default SignUpOrganization;
